<template>
    <svg class="icon" :style=cssProps :viewBox=viewBox>
        <path :d="path" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import icons from '../assets/icons/icons.json';

export default defineComponent({
  name: 'SVGIcon',
  props: {
    name: {
        type: String,
        required: true
    },
    width: {
        type: Number,
        required: true
    },
    height: {
        type: Number,
        required: true
    },
    viewBox: {
        type: String,
        required: true
    }
  },
  computed: {
    path: function() : string {
        return icons[this.$props.name];
    },
    cssProps: function() : string {
        return "width: " + this.$props.height + "px; height: " + this.$props.height + "px";
    }
  }
});
</script>