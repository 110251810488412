import { createI18n } from "vue-i18n";

import ru from "../locales/ru.json";
import en from "../locales/en.json";

const i18Plugin = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: { ru, en },
});

export default i18Plugin;