import { Game } from "./game.js";
import { resources } from "./resources.js";

/* eslint-disable */

export function initEngine(tgInitData = {}, envObject)
{
    
    const canvas = document.getElementById("gameWindow");
    if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const ctx = canvas.getContext("2d");

        let game = new Game(canvas, ctx, resources, tgInitData, envObject);

        window.addEventListener('resize', resizeCanvas, false);

        function resizeCanvas()
        {
            game.resizeWindow(window.innerWidth, window.innerHeight);
        }

        var keysPressed = {};
        window.addEventListener("keydown", function(event) {
            keysPressed[event.key] = true;
        });

        window.addEventListener('keyup', (event) => {
            delete keysPressed[event.key];
        });

        function draw() {
            window.setTimeout(function() {
                game.render();
                game.keyboardInput(keysPressed);
                requestAnimationFrame(draw);
            }, 1000 / 60);
        }
        requestAnimationFrame(draw);
    }
}