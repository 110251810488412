<template>
    <canvas id="gameWindow" width="300px" height="300px"></canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { initEngine } from '@/game/index.js';

import { UserDevice, DevicesEnum } from '@/types/UserDevice';

import WebApp from '@twa-dev/sdk';

export default defineComponent({
  name: 'GameView',
  beforeMount() {
    this.getDeviceInfo();
  },
  data() {
    return {
      userDeviceInfo: {
        deviceType: DevicesEnum.desktop
      } as UserDevice
    }
  },
  mounted() {
    WebApp.headerColor = "#23CD2A";
    const telegramData = {
      nickname: localStorage.getItem("first_name"),
      avatarURL: localStorage.getItem("photo_url")
    }

    initEngine(telegramData, { 
      testSuit: process.env.NODE_ENV === 'development',
      deviceType: this.userDeviceInfo.deviceType
    });
  },
  unmounted() {
    document.getElementById("gameWindow")?.remove();
  },
  methods: {
    getDeviceInfo() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      {
        this.$data.userDeviceInfo.deviceType = DevicesEnum.mobile;
      } else {
        this.$data.userDeviceInfo.deviceType = DevicesEnum.desktop;
      }
    }
  }
});
</script>

<style scoped>
  canvas, img {
        image-rendering: optimizeSpeed;
        image-rendering: -moz-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: optimize-contrast;
        image-rendering: pixelated;
        -ms-interpolation-mode: nearest-neighbor;

        transition: 0.7s all;
  }
</style>
