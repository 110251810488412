/**
*   Game Object
*   Class of game object
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

export class GameObject {
    
    /**
     * Creates GameObject instance
     * @param {number} x X coordinate
     * @param {number} y Y coordinate
     */
    constructor(x, y, isSprite = false, spriteOptions = {})
    {
        this.x = x;
        this.y = y;

        this.isSprite = isSprite;

        if (isSprite)
        {
            this.spriteOptions = spriteOptions;
        }
    }

    render(ctx, image, x, y, sizeWidth = 0, sizeHeight = 0)
    {
        if (!sizeWidth && !sizeHeight)
        {
            sizeWidth = image.width / this.spriteOptions.numberOfFrames;
            sizeHeight = image.height / this.spriteOptions.numberOfFrames;
        }

        ctx.drawImage(
            image.image,
            this.spriteOptions.frameIndexX * (image.width / this.spriteOptions.numberOfFramesX),
            this.spriteOptions.frameIndexY * (image.height / this.spriteOptions.numberOfFramesY),
            image.width / this.spriteOptions.numberOfFramesX,
            image.height / this.spriteOptions.numberOfFramesY,
            x,
            y,
            sizeWidth,
            sizeHeight
        );
    }

    getX()
    {
        return this.x;
    }

    getY()
    {
        return this.y;
    }
}