/* eslint-disable */

import { Game } from "../game";
import { PlayerMP } from "../player/playerMP";

/**
*   Socket.IO Network client
*   Multiplayer client
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

export class ClientMP {

    /**
     * Initialize multiplayer Socket.IO client
     * @param {Game} game Game class instance
     */  
    constructor(game, tgInitData = {}) {
        this.state = "CONNECTING";
        this.game = game;

        let connectAddress = "";
        if (!game.envObject.testSuit)
        {
            connectAddress = window.location.host;
        } else {
            connectAddress = window.location.host.split(":")[0] + ":3000";
        }

        this.socket = io.connect(`http${game.envObject.testSuit ? '' : 's'}://${connectAddress}/`, { query: "x=" + 0 + "&y=" + 0 + "&nickname=" + tgInitData.nickname + "&avatarURL=" + tgInitData.avatarURL });

        this.accountInfo = {
            nickname: tgInitData.nickname,
            avatarURL: tgInitData.avatarURL
        };

        this.playersPool = [];

        this.socket.on("connect", () => {
            console.log("[CLIENT-SOCKET] " + this.socket.id);
            this.state = "CONNECTED";
        });

        this.socket.on("playerMove", (data) => {
            let remotePlayer = this.playersPool.find(player => player.id === data.id);
            
            if (remotePlayer)
            {
                remotePlayer.player.x = data.x;
                remotePlayer.player.y = data.y;
                remotePlayer.player.spriteOptions = data.spriteOptions;
                remotePlayer.player.accountInfo = data.accountInfo;
            }
        });

        this.socket.on("syncPlayerList", (data) => {
            if (data.id != this.socket.id) {
                this.playersPool = [];
    
                data.forEach(playerData => {
                    this.playersPool.push({
                        id: playerData.id,
                        player: new PlayerMP(playerData.x, playerData.y, playerData.nickname, playerData.avatarURL)
                    });
                });
            }
        });

        this.socket.on("connect_error", (err) => {
            this.state = "CONNECT_ERROR";
        });
    }

    /**
     * Emits player move event
     * @param {Object} data Player move data
     * @example
     * Data:
     * {
     *    id: socket.id,
     *    x: x coord,
     *    y: y coord
     * }
     */  
    sendPlayerMove(data)
    {
        this.socket.emit("playerMove", data);
    }

    /**
     * Returns remote player list
     */  
    getPlayerPool()
    {
        return this.playersPool || [];
    }
}