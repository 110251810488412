import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "progressitem nested",
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    _createElementVNode("div", {
      class: "progress",
      style: _normalizeStyle(_ctx.getComputed(_ctx.$props))
    }, null, 4)
  ], 4))
}