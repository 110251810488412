<template>
    <div class="progressitem nested" :style="cssProps">
        <div class="progress" :style="getComputed($props)"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ComponentOptionsMixin, StyleValue } from 'vue';

export default defineComponent({
    name: 'HorizontalBar',
    props: {
        color: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true,
        },
        maxValue: {
            type: Number,
            required: true,
        },
        width: {
            type: String
        }
    },
    methods: {
        getComputed: function(properties: ComponentOptionsMixin)
        {
            let widthPercent = properties.value / properties.maxValue * 100;
            return "width: " + widthPercent + "%" + "; background-color: " + properties.color;
        }
    },
    computed: {
        cssProps: function() : StyleValue {
            return {
                '--width-size': this.$props.width ? this.$props.width : "50px",
            }
        }
    }
});
</script>

<style scoped>
    .progressitem {
      width: 100%;
      height: var(--width-size);
      background-color: var(--secondary-color-02);
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .nested {
      overflow: hidden;
    }

    @keyframes appearAnimation {
      from {
        width: 0%;
      }
    }

    .nested .progress {
      background-color: rgb(255, 0, 21);
      height: 100%;

      animation-delay: 3s;
      animation: appearAnimation ease 1.5s;
    }
</style>