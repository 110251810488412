<template>
    <div class="auth-block">
        <div></div>

        <ButtonLogin
        mode="redirect" 
        size="large"
        bot-username="melongame_bot"
        redirect-url="https://melonbot.su/auth/tg"
        />

        <div></div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { ButtonLogin } from 'televue';

    export default defineComponent({
        name: 'TelegramAuth',
        components: {
            ButtonLogin
        }
    });
</script>

<style scoped>
    div.auth-block {
        position: fixed;

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        background-color: var(--secondary-color-01);

        width: 100vw;
        height: 100vh;

        z-index: 10000;
    }
</style>