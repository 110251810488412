import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-598525ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "gameWindow",
  width: "300px",
  height: "300px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", _hoisted_1))
}