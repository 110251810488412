<template>
    <div :class="{ fadeout: !isShow }" class="splash-block">
        <div></div>

        <div class="splash-main">
            <div class="logotype">
                <img src="favicon.ico">
            </div>

            <div class="tooltip-block">
                <p class="tooltip">{{ $t('splashScreen.tip') }}</p> 
                <p class="tooltip-top">{{ $t(`tooltips[${this.$data.tooltipId}]`) }}</p>
            </div>
        </div>

        <div class="splash-footer"> 
            <p style="text-align: center;">{{ $t('splashScreen.footer.madeby') }}</p>
            <p class="splash-footer-text"><a href="https://t.me/augusthuh"><img src="/avatar.png" class="avatar-picture">&nbsp;&nbsp;fet1sov</a>&nbsp; &<a class="splash-yellow-text" href="https://www.youtube.com/channel/UC69c2lOdihIcNFcyZSdaECw"><img src="/avatar2.png" class="avatar-picture">&nbsp;&nbsp;Герментяния</a></p>
        </div>
    </div>

    
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
    name: 'SplashScreen',
    props: {
        tooltip: {
            type: String
        }
    },
    data: function() {
        return {
            isShow: true,
            tooltipId: Math.floor(Math.random() * 4),
            timer: {}
        };
    },
    created() {
        this.timer = setTimeout(() => this.lurkSplash(), 1500);
    },
    methods: { 
        lurkSplash: function() {
            this.isShow = false;
        }
    }
    });
</script>

<style scoped>
    div.splash-block {
        position: fixed;

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        background-color: var(--secondary-color-01);

        width: 100vw;
        height: 100vh;

        z-index: 10000;
    }

    .tooltip-top {
        margin-top: 0;
    }

    .splash-yellow-text {
        color: #d6ff00 !important;
    }

    .tooltip-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    p {
        color: var(--secondary-color-03);
    }

    p.tooltip {
        color: var(--secondary-color-04);
        font-weight: bold;

        margin-top: 5px;

        font-size: 24px;
        margin-bottom: 0;
    }

    .logotype {
        animation: 1s pulse-color-animation infinite;
    }

    .logotype :first-child {
        width: 128px;
        height: 128px;
    }

    .splash-footer-text {
        display: flex;
        flex-direction: row;
    }
    
    .splash-footer p {
        color: var(--secondary-color-04);
    }

    .splash-footer p,
    .splash-footer p a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }

    .splash-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .splash-footer p a {
        color: var(--secondary-color-03);
    }

    .avatar-picture {
        width: 32px;
        height: 32px;

        border-radius: 10px;
    }

    .fadeout {
        animation: fadeout 2s forwards;
    }

    @keyframes fadeout {
        to {
            opacity: 0;
            visibility: hidden;
        }
    }

    @keyframes pulse-color-animation {
        0% {
            filter: drop-shadow(0px 0px 0px rgb(255, 0, 0));
        }
        100% {
            filter: drop-shadow(0px 0px 50px rgb(255, 0, 0));
        }
    }
</style>
