import { ClientMP } from "./network/clientMP.js";
import { Player } from "./player/player.js";

import { Map } from "./objects/map.js";

/**
*   Game
*   Main game class
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

/* eslint-disable */

export class Game {
    
    /**
     * Game instance constructor
     * It creating the world and initialize basic resource
     * @param {any} canvas Canvas document element
     * @param {CanvasRenderingContext2D} ctx 2D context of canvas
     * @param {Resources} resources Game assets class
     */  
    constructor(canvas, ctx, resources, tgInitData = {}, envObject) {
        this.envObject = envObject;

        this.canvas = canvas;
        this.ctx = ctx;
        this.resources = resources;

        this.ctx.imageSmoothingEnabled = false;

        this.client = new ClientMP(this, tgInitData);
        this.player = new Player(0, 0, {}, this.client);
        this.map = new Map("assets/maps/test.json");
    }

    /**
     * Resize game window method
     * This method resize canvas window in body of DOM Tree
     * @param {number} Width of the window
     * @param {number} Height of the window
     */  
    resizeWindow(width, height) {
        this.canvas.width = width;
        this.canvas.height = height;

        this.render();
    }

    /**
     * Game rendering method
     */  
    render() {
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

        this.ctx.fillStyle = "#23CD2A";
        this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

        this.ctx.save();
        this.ctx.translate(-(this.player.getX() - this.canvas.width / 2 + 24), -(this.player.getY() - this.canvas.height / 2 + 24));

        if (this.client != undefined &&
            this.client.state == "CONNECT_ERROR")
        {
            this.ctx.filter = "blur(10px)";
        } else {
            this.ctx.filter = "none";
        }

        /* Rendering map objects */
        this.map.render(this.ctx, this.resources);

        /* Rendering player with other multiplayer people */
        this.player.render(this.ctx, this.resources);
        this.client.getPlayerPool().forEach((playerMP) => {
            if (playerMP.id != this.client.socket.id)
            {
                playerMP.player.render(this.ctx, this.resources);
            }
        });
        /* ================================== */

        this.ctx.filter = "none";

        if (this.client != undefined &&
            this.client.state == "CONNECT_ERROR")
        {
            this.ctx.drawImage(this.resources.images.no_connection.image, this.player.getX() - 32, this.player.getY() - 32, 128, 128);
        }

        this.ctx.restore();
    }

    /**
     * Game keyboard input hander
     */ 
    keyboardInput(keyCode)
    {
        if (this.client == undefined)
        {
            this.player.keyboardInput(keyCode);
        } else {
            if (this.client.state == "CONNECTED") {
                this.player.keyboardInput(keyCode);
            }
        }
    }
}