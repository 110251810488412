import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TelegramAuth = _resolveComponent("TelegramAuth")!
  const _component_SplashScreen = _resolveComponent("SplashScreen")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$data.testSuit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.$data.authed)
            ? (_openBlock(), _createBlock(_component_TelegramAuth, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$data.authed || _ctx.$data.testSuit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SplashScreen)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterView)
  ], 64))
}