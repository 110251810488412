<template>
    <div class="buy-item-container">
        <ul class="buy-item-list">
            <li>
                <RouterLink class="routerLink" to="/game">
                    <svg viewBox="0 0 24 24" fill="none" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 0.75H1C0.447715 0.75 0 1.19772 0 1.75V2.25C0 2.80228 0.447715 3.25 1 3.25H3.0119L5.73626 14.7312C6.18267 16.6125 7.84065 17.9508 9.76471 17.9987V18H17.5874C19.5362 18 21.2014 16.5956 21.5301 14.6747L22.7857 7.33734C22.9947 6.11571 22.0537 5 20.8143 5H5.99657L5.21623 1.7114C5.08251 1.14787 4.57918 0.75 4 0.75Z" fill="#000000"></path> <path d="M10 21C10 22.1046 9.10457 23 8 23C6.89543 23 6 22.1046 6 21C6 19.8954 6.89543 19 8 19C9.10457 19 10 19.8954 10 21Z" fill="#000000"></path> <path d="M21 21C21 22.1046 20.1046 23 19 23C17.8954 23 17 22.1046 17 21C17 19.8954 17.8954 19 19 19C20.1046 19 21 19.8954 21 21Z" fill="#000000"></path> </g></svg>
                    {{ this.$i18n.t('buttons.buy') }}
                </RouterLink>
            </li>
            <li><ListStat color="#00AF42" :value="1000" icon="dollar" viewBox="0 0 24 24" /></li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ListStat from '../../components/ListStat.vue';

import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'VehicleView',
  components: {
    ListStat,
    RouterLink
  }
});
</script>

<style scoped>
    .buy-item-container {
        display: flex;
        justify-content: center;
    }

    .routerLink {
        width: 100%;
    }

    .buy-item-list {
        display: flex;
        flex-direction: column;
    }

    .buy-item-list li {
        margin-bottom: 15px;
    }
</style>