<template>
    <li class="liststat" :style="cssProps">
        <SVGIcon :name="icon" :width=32 :height=32 :viewBox=viewBox v-if="!this.$props.iconel" />
        <SVGELIcon :name="icon" :width=32 :height=32 v-else />
        <strong>{{ formatNumber($props.value) }}</strong>
    </li>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from 'vue';
import SVGIcon from './SVGIcon.vue';
import SVGELIcon from './SVGELIcon.vue';

export default defineComponent({
  name: 'ListStat',
  props: {
    color: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true,
    },
    iconel: {
      type: Boolean, 
    },
    icon: {
      type: String,
      required: true
    },
    viewBox: {
      type: String,
      required: true
    }
  },
  methods:
  {
    formatNumber: function (number: number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  computed: {
    cssProps: function() : StyleValue {
      return {
        '--fill-color': this.$props.color,
      }
    }
  },
  components: {
    SVGIcon,
    SVGELIcon
  }
});
</script>

<style scoped>
    .liststat {
      width: 100%;
    }

    .liststat svg path,
    .liststat strong {
        color: var(--fill-color);

        text-shadow: 0px 0px 20px var(--fill-color);
        
        font-size: var(--font-size-header-01);
    }

    .liststat svg path,
    .liststat svg {
        stroke: var(--fill-color);
        width: 32px;
        height: 32px;
    }
</style>