import { GameObject } from "../objects/gameObject.js";

/**
*   Player class
*   Class of game character
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

export class Player extends GameObject {
    constructor(x, y, attributes = {}, remoteClient = null)
    {
        super(x, y);
        this.attributes = attributes;
        
        this.remoteClient = remoteClient;

        this.isSprite = true;
        this.spriteOptions = {
            frameIndexX: 0,
            frameIndexY: 0,
            ticksPerFrame: 10,
            numberOfFramesX: 5,
            numberOfFramesY: 4,

            tickCount: 0,
        };
    }

    /**
     * Rendering method of Player
     * @param {CanvasRenderingContext2D} ctx 2D context of canvas
     * @param {Resources} resources Game assets class
     */  
    render(ctx, resources)
    {
        super.render(ctx, resources.images.player, this.x, this.y, 64, 64);
    }

    /**
     * Keyboard input for the player 
     */ 
    keyboardInput(keyCode)
    {
        if (keyCode.w) {
            this.spriteOptions.frameIndexY = 1;
            
            this.y -= this.attributes.velocity ? this.attributes.velocity : 6;
        } else if (keyCode.s) {
            this.spriteOptions.frameIndexY = 0;

            this.y += this.attributes.velocity ? this.attributes.velocity : 6;
        }

        if (keyCode.a) {
            this.spriteOptions.frameIndexY = 3;

            this.x -= this.attributes.velocity ? this.attributes.velocity : 6;
        } else if (keyCode.d) {
            this.spriteOptions.frameIndexY = 2;

            this.x += this.attributes.velocity ? this.attributes.velocity : 6;
        }

        if (keyCode.w || keyCode.s || keyCode.a || keyCode.d)
        {
            if (this.spriteOptions.frameIndexX < this.spriteOptions.numberOfFramesX) {
                this.spriteOptions.tickCount++;

                if (this.spriteOptions.tickCount > this.spriteOptions.ticksPerFrame) {
                    this.spriteOptions.tickCount = 0;
                    if (this.spriteOptions.frameIndexX < this.spriteOptions.numberOfFramesX) {
                        this.spriteOptions.frameIndexX++;
                    } else {
                        this.spriteOptions.frameIndexX = 0;
                    }
                }
            } else {
                this.spriteOptions.frameIndexX = 0;
            }
        } else {
            this.spriteOptions.frameIndexX = 0;
        }
            

        if (this.remoteClient) {
            this.remoteClient.sendPlayerMove({
                id: this.remoteClient.socket.id,
                accountInfo: this.remoteClient.accountInfo,
                x: this.x,
                y: this.y,
                spriteOptions: this.spriteOptions
            });
        }
    }
}