import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab6ae8f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progressitem nested" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SVGIcon, {
        name: _ctx.icon,
        width: 32,
        height: 32,
        viewBox: _ctx.viewBox
      }, null, 8, ["name", "viewBox"]),
      _createElementVNode("div", {
        class: "progress",
        style: _normalizeStyle(_ctx.getComputed(_ctx.$props))
      }, null, 4)
    ])
  ]))
}