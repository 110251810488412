<template>
    <div class="auth-block"></div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'PostAuthTG',
        mounted() {
            const urlParams = new URL(location.href).searchParams;

            for (const [key, value] of urlParams) {
                localStorage.setItem(key, value);
            }

            window.location.href = `${window.location.protocol}//${window.location.host}`;
        }
    });
</script>

<style scoped>
    div.auth-block {
        position: fixed;

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        background-color: var(--secondary-color-01);

        width: 100vw;
        height: 100vh;

        z-index: 10000;
    }
</style>