/**
*   Resources
*   Class which is initializing 
*   all image and sound resouces of game engine
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

class Resources {
    constructor() {
        /* Images load */
        this.toLoad = {
          no_connection: "/assets/content/no_connection.png",

          player: "/assets/content/character.png",
          cabin: "/assets/content/cabin.png"
        };

        this.images = {};
        Object.keys(this.toLoad).forEach(key => {
          const img = new Image();
          img.src = this.toLoad[key];
          this.images[key] = {
            image: img,
            isLoaded: false,
          }
          img.onload = () => {
            this.images[key].isLoaded = true;
            this.images[key].width = this.images[key].image.width;
            this.images[key].height = this.images[key].image.height;
          }
        });
        /* ============ */

        /* Sound load */
        this.toLoad = {
          
        };

        this.sounds = {};
        Object.keys(this.toLoad).forEach(key => {
          const audio = new Audio(this.toLoad[key]);
          this.sounds[key] = {
            audio: audio,
            isLoaded: false,
          }
          audio.onload = () => {
            this.sounds[key].isLoaded = true;
          }
        });
        /* ============ */
  }
}

export const resources = new Resources();