<template>
    <li>
      <div class="progressitem nested">
        <SVGIcon :name="icon" :width=32 :height=32 :viewBox="viewBox" />
        <div class="progress" :style="getComputed($props)"></div>
      </div>
    </li>
</template>

<script lang="ts">
import { ComponentOptionsMixin } from 'vue';
import { defineComponent } from 'vue';
import SVGIcon from './SVGIcon.vue';

export default defineComponent({
  name: 'RectStat',
  props: {
    color: String,
    value: Number,
    maxValue: Number,
    icon: {
      type: String,
      required: true
    },
    viewBox: {
      type: String,
      required: true
    }
  },
  methods:
  {
    getComputed: function(properties: ComponentOptionsMixin)
    {
      let heightPercent = properties.value / properties.maxValue * 100;
      return "height: " + heightPercent + "%" + "; background-color: " + properties.color;
    }
  },
  components: {
    SVGIcon
  }
});
</script>

<style scoped>
    .progressitem {
      width: 48px;
      height: 48px;
      background-color: var(--secondary-color-02);
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      position: relative;
    }

    .progressitem svg {
      position: absolute;

      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;

      fill: #FFFFFF;

      width: 36px;
      height: 36px;
    }

    .nested {
      overflow: hidden;
    }

    @keyframes appearAnimation {
      from {
        height: 0%;
      }
    }

    .nested .progress {
      background-color: rgb(255, 0, 21);
      width: 100%;

      animation-delay: 3s;
      animation: appearAnimation ease 1.5s;
    }
</style>