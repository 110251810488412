import { createApp } from 'vue';
import App from './App.vue';
import i18Plugin from './plugins/i18n';

import { createRouter, createWebHistory } from 'vue-router'
import ProfileView from './views/ProfileView.vue';
import GameView from './views/GameView.vue';
import VehicleView from './views/modal/VehicleView.vue';

import PostAuthTG from './views/auth/PostAuthTG.vue';

import WebApp from '@twa-dev/sdk';
import MenuView from './views/MenuView.vue';

WebApp.headerColor = "#000000";
WebApp.expand();

const routes = [
  { path: '/', component: ProfileView },
  { path: '/game', component: GameView },
  { path: '/test', component: VehicleView },

  { path: '/menu', component: MenuView },

  { path: '/auth/tg', component: PostAuthTG },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

createApp(App).use(router).use(i18Plugin).mount('#app')