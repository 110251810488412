<template>
  <div v-if="!$data.testSuit">
    <TelegramAuth v-if="!$data.authed"/>
  </div>

  <div v-if="$data.authed || $data.testSuit">
    <SplashScreen />
  </div>

  <RouterView />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SplashScreen from './views/SplashScreen.vue';
import TelegramAuth from './views/TelegramAuth.vue';
import { RouterView } from 'vue-router';

import WebApp from '@twa-dev/sdk';

let playerStats = {
  level: 1,
  health: 10000,
  money: 100,
};

export default defineComponent({
  name: 'App',
  data() {
    return {
      authed: false,
      testSuit: process.env.NODE_ENV === 'development',
      playerStats: playerStats
    }
  },
  mounted() {
    if (WebApp.initData)
    {
      localStorage.setItem("username", String(WebApp.initDataUnsafe.user?.username));
      localStorage.setItem("first_name", String(WebApp.initDataUnsafe.user?.first_name));
      localStorage.setItem("last_name", String(WebApp.initDataUnsafe.user?.last_name));
      localStorage.setItem("language_code", String(WebApp.initDataUnsafe.user?.language_code));
      localStorage.setItem("hash", WebApp.initDataUnsafe.hash);
      this.$data.authed = true;
    }

    if (localStorage.getItem("hash"))
    {
      this.$data.authed = true;
    }
  },
  components: {
    RouterView,
    TelegramAuth,
    SplashScreen
  },
});
</script>

<style>
  @font-face {
    font-family: 'Inter-Regular';
    src: url(./assets/Inter-Regular.ttf) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Inter-Bold';
    src: url(./assets/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
  }

  :root {
    --primary-color-01: #000000;
    --primary-color-02: #00AF42;
    --secondary-color-01: #000000;
    --secondary-color-02: #151515;
    --secondary-color-03: #FFFFFF;
    --secondary-color-04: #575757;

    --header_bg_color: var(--primary-color-01);

    --font-size-header-01: 32px;
    --font-size-header-02: 20px;
  }

  body {
    margin: 0;
    background-color: var(--secondary-color-01);

    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  * {
    font-family: 'Inter-Regular', Tahoma, Geneva, Verdana, sans-serif;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .routerLink {
    display: flex;
    justify-content: center;

    align-items: center;

    background-color: var(--secondary-color-03);
    color: var(--secondary-color-01);

    font-weight: bold;

    transition: 0.7s all;

    width: 40%;

    padding: 5px;
    border-radius: 10px;
  }

  .routerLink-second {
    display: flex;
    justify-content: center;

    align-items: center;

    background-color: var(--secondary-color-02);
    color: var(--secondary-color-03);

    font-weight: bold;

    transition: 0.7s all;

    padding: 5px;
    border-radius: 10px;
  }

  .routerLink-second:hover {
    background-color: var(--secondary-color-04);
  }

  .routerLink:hover {
    background-color: var(--secondary-color-04);
  }

  .routerLink:active {
    background-color: var(--secondary-color-02);
  }
</style>
