import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_SVGELIcon = _resolveComponent("SVGELIcon")!

  return (_openBlock(), _createElementBlock("li", {
    class: "liststat",
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    (!this.$props.iconel)
      ? (_openBlock(), _createBlock(_component_SVGIcon, {
          key: 0,
          name: _ctx.icon,
          width: 32,
          height: 32,
          viewBox: _ctx.viewBox
        }, null, 8, ["name", "viewBox"]))
      : (_openBlock(), _createBlock(_component_SVGELIcon, {
          key: 1,
          name: _ctx.icon,
          width: 32,
          height: 32
        }, null, 8, ["name"])),
    _createElementVNode("strong", null, _toDisplayString(_ctx.formatNumber(_ctx.$props.value)), 1)
  ], 4))
}