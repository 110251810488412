import { GameObject } from "./gameObject.js";

export class MapObject extends GameObject {

    /**
     * Creates map object
     * @param {String} name Map object name
     * @param {String} asset Game asset name in resources
     * @param {Number} x X coord
     * @param {Number} y Y coord
     */  
    constructor(name, asset, x, y)
    {
        super(x, y);
        this.name = name;
        this.asset = asset;
    }

    /**
     * Renders map object
     * @param {CanvasRenderingContext2D} ctx 2D context of canvas
     * @param {Resources} resources Game assets class
     */  
    render(ctx, resources)
    {
        ctx.drawImage(resources.images[this.asset].image, this.x, this.y, resources.images[this.asset].image.width, resources.images[this.asset].image.height);
    }
}