<template>
    <div v-html="path" :style=cssProps>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import icons from '../assets/icons/icons_elements.json';

export default defineComponent({
  name: 'SVGELIcon',
  props: {
    name: {
        type: String,
        required: true
    },
    width: {
        type: Number,
        required: true
    },
    height: {
        type: Number,
        required: true
    }
  },
  computed: {
    path: function() : string {
        return icons[this.$props.name];
    },
    cssProps: function() : string {
        return "width: " + this.$props.height + "px; height: " + this.$props.height + "px";
    }
  }
});
</script>