import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "icon",
    style: _normalizeStyle(_ctx.cssProps),
    viewBox: _ctx.viewBox
  }, [
    _createElementVNode("path", { d: _ctx.path }, null, 8, _hoisted_2)
  ], 12, _hoisted_1))
}