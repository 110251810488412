/* eslint-disable */

/**
*   Map class
*   All map object load
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

import { MapObject } from "./mapObject";

export class Map {

    /**
     * Load map from
     * @param {String} mapPath file path 
     */
    constructor(mapPath)
    {
        let mapObjects = [];

        this.readFile(mapPath, function(text){
            let mapData = JSON.parse(text);

            mapData.forEach((object) => {
                mapObjects.push(new MapObject(object.name, object.asset, object.x, object.y));
            });
        });

        this.objects = mapObjects;
    }

    /**
     * Renders all map objects
     * @param {CanvasRenderingContext2D} ctx 2D context of canvas
     * @param {Resources} resources Game assets class
     */  
    render(ctx, resources)
    {
        this.objects.forEach((object) => {
            object.render(ctx, resources);
        });
    }

    readFile(file, callback) {
        var rawFile = new XMLHttpRequest();
        rawFile.overrideMimeType("application/json");
        rawFile.open("GET", file, true);
        rawFile.onreadystatechange = function() {
            if (rawFile.readyState === 4 && rawFile.status == "200") {
                callback(rawFile.responseText);
            }
        }
        rawFile.send(null);
    }
}