<template>
    <canvas ref="charView" class="char-view" width="200" height="200"></canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CharacterView',
  mounted() {
        const ctx = (this.$refs['charView'] as HTMLCanvasElement).getContext('2d');
        ctx!.imageSmoothingEnabled = false;

        const img = new Image();
        img.src = "assets/content/character.png";

        img.onload = function () {
            ctx!.drawImage(img, 0, 0, img.width * 6.5, img.height * 6.5);
        }
  }
});
</script>

<style scoped>
    canvas, img {
        image-rendering: optimizeSpeed;
        image-rendering: -moz-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: optimize-contrast;
        image-rendering: pixelated;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .char-view {
        width: 200px;
        height: 200px;
        image-rendering: pixelated;
    }
</style>