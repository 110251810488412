import { GameObject } from "../objects/gameObject.js";

/**
*   Multiplayer player class
*   Class of remote player
*
*   @author fet1sov <prodaugust21@gmail.com>
*/

export class PlayerMP extends GameObject {

    /**
     * Creating Player Multiplayer
     * @param {Number} x X coord
     * @param {Number} y Y coord
     */
    constructor(x, y, nickname, avatarURL) {
        super(x, y);
        
        this.accountInfo = {
            nickname: nickname,
            avatarURL: decodeURI(avatarURL)
        };

        this.x = x;
        this.y = y;

        this.isSprite = true;
        this.spriteOptions = {
            frameIndexX: 0,
            frameIndexY: 0,
            ticksPerFrame: 0,
            numberOfFramesX: 5,
            numberOfFramesY: 4,
        };

        this.avatarPic = new Image();
        this.avatarPic.src = avatarURL ? avatarURL : "";
    }

    /**
     * Rendering method of Player
     * @param {CanvasRenderingContext2D} ctx 2D context of canvas
     * @param {Resources} resources Game assets class
     */  
    render(ctx, resources)
    {
        /* Nickname */

        // shadow
        ctx.font = "bold 14px Arial";
        ctx.fillStyle = "#000000";
        ctx.textAlign = "center";
        ctx.fillText(this.accountInfo.nickname, this.x + 34, this.y + 3);

        // main
        ctx.font = "bold 14px Arial";
        ctx.fillStyle = "#FFFFFF";
        ctx.textAlign = "center";
        ctx.fillText(this.accountInfo.nickname, this.x + 32, this.y);

        /* Avatar pic */
        // if (this.accountInfo.avatarURL)
        // {
        //     ctx.drawImage(this.avatarPic, this.x - (this.accountInfo.nickname.length / 2) - 16, this.y - 16, 24, 24);
        // }
        

        /* Player */
        super.render(ctx, resources.images.player, this.x, this.y, 64, 64);
    }
}